<template>
  <div
    class="userservice"
    :style="{ height: clientHeight - 130 + 'px' }"
    style="overflow-y: scroll"
  >
    <el-row style="width: 95%; margin: 0 auto">
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <div>{{ form.memberCount }}</div>
          <div>用户数量</div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple-light">
          <div>{{ form.equipmentCount }}</div>
          <div>设备数量</div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <div>{{ form.alarmTodayCount }}</div>
          <div>今日报警数量</div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple-light">
          <div>{{ form.alarmAllCount }}</div>
          <div>总报警数量</div>
        </div>
      </el-col>
    </el-row>
    <div class="fromMain">
      <span class="fromTitle">最新十条报警记录</span>
      <el-table
        :data="tableData"
        style="
          width: 100%;
          border: 1px solid rgb(228, 231, 237);
          border-radius: 8px;
          padding: 10px;
          margin-top: 1%;
          box-shadow: 0px 0px 2px -1px;
          max-height: 400px;
        "
      >
        <!-- <el-table-column
          prop="alarmNo"
          label="报警编号"
          width="155"
        ></el-table-column> -->
        <el-table-column
          prop="alarmDate"
          label="报警时间"
          width="160"
        ></el-table-column>
        <!-- <el-table-column
          prop="situation"
          label="报警类型"
          width="110"
        ></el-table-column> -->
        <!-- <el-table-column prop="imei" label="IMEI" width="150"></el-table-column> -->
        <el-table-column prop="memberNo" label="用户编号"></el-table-column>
        <el-table-column prop="memberName" label="用户名称"></el-table-column>
        <el-table-column
          prop="phone"
          label="联系电话"
          width="130"
        ></el-table-column>
        <el-table-column
          prop="idcard"
          label="身份证号码"
          width="166"
        ></el-table-column>
        <el-table-column
          prop="address"
          label="详细地址门牌号"
          width="130"
        ></el-table-column>
        <el-table-column prop="pushStatus" label="推送状态"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { apiShan } from "../../../api1/shanConfig";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      tableData: [],
      form: {},
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  created() {
    this.getHome();
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //首页统计
    getHome() {
      apiShan.home().then((res) => {
        if (res.data.code == 200) {
          console.log(res.data, "首页统计");
          this.form = res.data.result;
        }
      });
      apiShan
        .getLists({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
        })
        .then((res) => {
          // console.log("400中心：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
  },
};
</script>

<style lang='less' scoped>
* {
  margin: 0;
  padding: 0;
}
/deep/.grid-content {
  background-color: #0085d0;
  padding: 21px 16px;
  width: 72%;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  border-radius: 8px;
  line-height: 29px;
  margin: 0 auto;
}
.fromMain {
  width: 88%;
  margin: 0 auto;
  margin-top: 2%;
}
.fromTitle {
  font-size: 20px;
  color: #92aca7;
  font-weight: bold;
}
/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: #fff;
}
/deep/.el-table .cell{
  text-align: center;
}
</style>